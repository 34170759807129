
import { computed, defineComponent, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";
import { useRouterUtils } from "@/utils/mixins";

import CPatientSearch from "@/views/patient/search.vue";

import { Patient } from "@/store/patient/types";

const VConsultation = defineComponent({
  name: "VConsultation",
  components: { CPatientSearch },
  setup() {
    useHead({ title: "Agendar consulta | obmed" });

    const { normalizeRouteParam } = useRouterUtils();

    const route = useRoute();
    const router = useRouter();
    const steps = ref<string[]>();
    const currentStep = computed(() =>
      route.name?.toString().includes("-specialist")
        ? steps.value?.findIndex((step) => step === "Especialista")
        : route.name?.toString().includes("-specialty")
        ? steps.value?.findIndex((step) => step === "Especialidade")
        : route.name?.toString().includes("-establishment")
        ? steps.value?.findIndex((step) => step === "Estabelecimento")
        : (steps.value?.length || 0) - 1
    );
    const patient = ref<Patient | null>(null);

    function handleCurrentFlow(routeName: any) {
      if (routeName === "consultation") router.push({ query: { fw: undefined } });
      else if (routeName.includes("consultation-") && !route.query.fw) router.push({ name: "consultation" });
    }

    handleCurrentFlow(route.name);
    watch(() => route.name, handleCurrentFlow);

    function setSteps(flow: any) {
      if (flow === "st") steps.value = ["Paciente", "Especialista", "Estabelecimento"];
      else if (flow === "sy" || flow === "de")
        steps.value = ["Paciente", "Especialidade", "Estabelecimento", "Especialista"];
    }

    setSteps(route.query.fw);
    watch(() => route.query.fw, setSteps);

    return { patient, steps, currentStep, normalizeRouteParam };
  },
});

export default VConsultation;
